/* ------------------------------------*\
    #ALL BRANDS PAGE / BRAND LIST
\*------------------------------------ */

$brand-department-switch-button-height: 30px;
$brand-department-switch-arrow-fonts-size: 16px;
$brand-department-switch-arrow-ml: -$brand-department-switch-arrow-fonts-size / 2;
$brand-department-switch-arrow-pos-b: -14px;
$brand-department-switch-arrow-pos-l: 50%;
$brand-department-switch-padding: 5px 20px;

// Department Switch
.c-brand-department-switch {
	border-bottom: 1px solid $color-gray-light;
}

.c-brand-department-switch__link {
	display: inline-block;
	font-weight: $font-weight-medium;

	@include font-size($secondary-font-size-xl, $brand-department-switch-button-height);

	position: relative;
	text-align: center;

	> a {
		color: $color-gray-dark;
		display: block;
		height: 100%;
		padding: $brand-department-switch-padding;

		&:hover {
			color: $color-black;
		}

		&.is-active {
			color: $color-black;

			&::after {
				bottom: $brand-department-switch-arrow-pos-b;
				background-color: $color-white;
				color: $color-gray-light;
				content: $icon-font--arrow-down;

				@include icon-font($brand-department-switch-arrow-fonts-size);

				left: $brand-department-switch-arrow-pos-l;
				margin-left: $brand-department-switch-arrow-ml;
				position: absolute;
			}
		}
	}
}
