/* ------------------------------------*\
    #ALL BRANDS PAGE / BRAND LIST Letter Navigation
\*------------------------------------ */

$brandlist-button-padding: 5px 10px;
$brandlist-button-active-offset-bottom: 4px;
$brandlist-button-active-width: 40%;
$brandlist-button-active-offset: -60%;
$brandlist-reserve-space-padding: 80px;
$brandlist-reserve-space-padding-m-down: 66px;
$brandlist-button-wrapper-translate-pos-t: 2 * $gl_header-fixed-height;
$brandlist-button-wrapper-pos-t: $gl_header-fixed-height;
$brandlist__button-row-padding: 26px;
$brandlist-offcanvas-nav-transition-speed: 0.3s;

//-------------------------------------------
// Styling der .scrollbar() - Buttons
//-------------------------------------------
// todo: .scrollbar scheint hier nicht die optimale Lösung zu sein.

$brandlist-button-scrollarrow-width: 20px;
$brandlist-button-scrollarrow-height: 30px;
$brandlist-button-scrollarrow-top: 8px;
$brandlist-button-scrollarrow-iconfontsize: 12px;
$brandlist-button-scrollarrow-lineheight: 30px;
$brandlist-button-scrollarrow-pl: 4px;

.c-brandlist-reserve-space {
	&.is-fixed {
		padding-top: $brandlist-reserve-space-padding;

		@include media-query(m-down) {
			padding-top: $brandlist-reserve-space-padding-m-down;
		}
	}
}

.c-brandlist-teaser {
	position: relative;

	@include aspect-ratio(640, 400);
}

.c-brandlist__button-wrapper {
	border-top: 1px solid $color-gray-light;
	border-bottom: 1px solid $color-gray-light;
	background: $color-white;
	left: 0;
	z-index: 5;

	@include media-query(m-down) {
		&.is-activeSearch {
			display: none;
		}
	}

	.t-classic & {
		border-color: $color-gray-light;
	}

	@include media-query(m-up) {
		// Department Switch → Brand List Search (mobile) → 0-9 A-Z Menu
		.c-brand-department-switch + .u-hide-m-up + .c-brandlist-reserve-space + & {
			border-top: none;
		}
	}

	&.is-fixed {
		border-top: none;
		width: 100%;
		z-index: 50;
		position: fixed;
		top: -3.285714285714286rem;
		transform: translate3d(0, 6.571428571428571rem, 0);
		transform-style: preserve-3d;
		transition: transform 0.5s ease 0s;
		background-color: $color-white;

		@include media-query(m-down) {
			.c-brandlist__button-content {
				transition: left $brandlist-offcanvas-nav-transition-speed;
				left: 0;
			}
		}
	}

	.scroll-arrow {
		display: inline-block;
		cursor: pointer;
		position: absolute;
		width: $brandlist-button-scrollarrow-width;
		height: $brandlist-button-scrollarrow-height;
		top: $brandlist-button-scrollarrow-top;
		background: #fff;

		&.scroll-arrow_more {
			right: 0;
		}

		&::after {
			@include icon-font($brandlist-button-scrollarrow-iconfontsize);

			line-height: $brandlist-button-scrollarrow-lineheight;
		}

		&.scroll-arrow_less::after {
			content: $icon-font--arrow-left-big;
			padding-left: $brandlist-button-scrollarrow-pl;
		}

		&.scroll-arrow_more::after {
			content: $icon-font--arrow-right-big;
			padding-left: $brandlist-button-scrollarrow-pl;
		}
	}
}

.c-brandlist__button-content {
	position: relative;
	padding-top: $inuit-base-spacing-unit;
	padding-bottom: $inuit-base-spacing-unit;

	@include media-query(m-down) {
		padding-top: $inuit-base-spacing-unit--small;
		padding-bottom: $inuit-base-spacing-unit;
	}
}

.js-brandlist-buttons.no-plugin {
	overflow-x: auto;
	overflow-y: hidden;
	-webkit-overflow-scrolling: touch;
}

.c-brandlist__button-row {
	display: table;
	min-width: 100%;
	padding-right: $brandlist__button-row-padding;
	padding-left: $brandlist__button-row-padding;

	.no-plugin > & {
		padding-left: 0;
		padding-right: 0;
	}
}

.c-brandlist__button {
	background: transparent;
	border: none;
	display: table-cell;

	@include font-size($heading-secondary-font-size-gamma);

	padding: $brandlist-button-padding;
	text-align: center;
	white-space: nowrap;
	font-weight: bold;

	&.is-disabled {
		color: $color-gray-light;
		cursor: default;
	}

	&.is-active {
		position: relative;

		&::before {
			content: "";
			position: absolute;
			width: $brandlist-button-active-width;
			height: 1px;
			bottom: $brandlist-button-active-offset-bottom;
			left: 50%;
			transform: translateX($brandlist-button-active-offset);
			border-bottom: 1px solid $color-black;
		}
	}

	.t-classic & {
		&.is-filtered {
			color: $color-classic-primary;

			&::before {
				border-bottom-color: $color-classic-primary;
			}
		}
	}
}
