/* ------------------------------------*\
    #BANDEROLE
\*------------------------------------ */

.c-banderole {
	display: none;
	transition: transform 0.3s ease-in;
	transform: translateY(-100%);
	border-bottom: 1px solid $color-gray-light;
	padding: 9px;

	&.slided-in {
		transform: translateY(0);
	}

	&__link:last-child {
		@include media-query(l-down) {
			display: block;
			margin-top: 1px;
		}
	}
}
