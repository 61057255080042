/* ------------------------------------*\
    #MAIN CONTENT
\*------------------------------------ */

$main-offset-top: $gl_header-logo-bar-height + $gl_header-logo-bar-margin + $gl_header-top-links-height;

$main-offset-top-l-down: $gl_header-logo-bar-height + $gl_header-top-links-height-l-down;

$main-content-min-height: 1040px;
$main-content-margin-bottom: 20px;

.c-main-content {
	margin-bottom: 20px;
	min-height: $main-content-min-height;
	padding-top: 3.2857142rem;
	position: relative;

	.is-landingpage &,
	.is-checkout &,
	.page-ContactPage &,
	.template-pages-SignInPageTemplate & {
		padding-top: 0;
	}

	.is-site-classic .template-pages-SignInPageTemplate:not(.page-registerConfirmationPage) & {
		margin-top: 3.2857142rem;
	}

	// checkout has a separate header-design
	.page-multiStepCheckoutSummaryPage & {
		min-height: unset;
	}

	// Classic Map Page has no header
	.template-pages-classic-mapPage & {
		margin-bottom: 0;
		min-height: 0;
		padding-top: 0;
	}

	// My Account Page
	.page-account & {
		min-height: 80vh;
	}

	// when vue checkout is active
	&.c-main-content-vue-checkout {
		padding-top: 0;
	}
}
